// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #313131;
}
button{
  color: #313131;
  border-style: none;
  border-radius:8px;
  font-weight: 500;
} 
a {
  text-decoration: none;
  color: #313131; 

}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;EACrC,cAAc;AAChB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,cAAc;;AAEhB","sourcesContent":["\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nhtml,\nbody {\n  width: 100%;\n  height: 100%;\n  overflow-x: hidden;\n}\n\nbody {\n  font-family: \"Montserrat\", sans-serif;\n  color: #313131;\n}\nbutton{\n  color: #313131;\n  border-style: none;\n  border-radius:8px;\n  font-weight: 500;\n} \na {\n  text-decoration: none;\n  color: #313131; \n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
