// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Montserrat", sans-serif;
  background-color: white;
height: 100vh;
  overflow-x: hidden; 
}
`, "",{"version":3,"sources":["webpack://./src/components/app/app.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,uBAAuB;AACzB,aAAa;EACX,kBAAkB;AACpB","sourcesContent":["body {\n  font-family: \"Montserrat\", sans-serif;\n  background-color: white;\nheight: 100vh;\n  overflow-x: hidden; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
